import '../../css/mapaPaises.css'
import '../../css/app.css'
import map from '../../assets/mapaPaises.svg'
import background from '../../assets/barcoMapa.jpg'
import { useTranslation } from "react-i18next";


export const MapaPaises = () => {
    const { t, i18n } = useTranslation(["translation"])
    return (
        <section className='mapaPaises'>
            <img src={background} alt="" className="mapaPaises__bgImg" />
            <h2 className='mapaPaises__title'>{t("homePage.countries")}</h2>
            <img className='mapaPaises__mapa' src={map} alt="Paises donde exportamos" />
        </section>
    )
}