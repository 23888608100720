import "../../css/estadisticas.css";
import "../../css/app.css";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

export const Estadisticas = () => {
    const { t, i18n } = useTranslation(["translation"]);
    return (
        <section className="mainEstadisticas">
            <article className="mainEstadisticas__card">
                <InView>
                    {({ inView, ref }) => (
                        <div className="mainEstadisticas__cardNumber" ref={ref}>
                            {inView ? (
                                <CountUp
                                    className="mainEstadisticas__cardNumber"
                                    end={30}
                                />
                            ) : (
                                0
                            )}
                            .K
                        </div>
                    )}
                </InView>
                <p className="mainEstadisticas__cardText">
                    {t("homePage.statistics.tonsProcessed")}
                </p>
            </article>
            <article className="mainEstadisticas__card">
                <InView>
                    {({ inView, ref }) => (
                        <div className="mainEstadisticas__cardNumber" ref={ref}>
                            {inView ? (
                                <CountUp
                                    className="mainEstadisticas__cardNumber"
                                    end={20}
                                />
                            ) : (
                                0
                            )}
                            .K
                        </div>
                    )}
                </InView>
                <p className="mainEstadisticas__cardText">
                    {t("homePage.statistics.tonsExported")}
                </p>
            </article>
            <article className="mainEstadisticas__card">
                <InView>
                    {({ inView, ref }) => (
                        <div className="mainEstadisticas__cardNumber" ref={ref}>
                            {inView ? (
                                <CountUp
                                    className="mainEstadisticas__cardNumber"
                                    end={40}
                                />
                            ) : (
                                0
                            )}
                        </div>
                    )}
                </InView>
                <p className="mainEstadisticas__cardText">
                    {t("homePage.statistics.countries")}
                </p>
            </article>
            <article className="mainEstadisticas__card">
                <InView>
                    {({ inView, ref }) => (
                        <div className="mainEstadisticas__cardNumber" ref={ref}>
                            +
                            {inView ? (
                                <CountUp
                                    className="mainEstadisticas__cardNumber"
                                    end={15}
                                />
                            ) : (
                                0
                            )}
                        </div>
                    )}
                </InView>
                <p className="mainEstadisticas__cardText">
                    {t("homePage.statistics.years")}
                </p>
            </article>
        </section>
    );
};
