import React from "react";
import "../../css/productos.css";
import alubia from "../../assets/productos/alubiabeans.png";
import black from "../../assets/productos/blackbeans.png";
import reddark from "../../assets/productos/darkbeans.png";
import redlight from "../../assets/productos/lightbeans.png";
import adzuki from "../../assets/productos/adzukibeans.png";
import cranberry from "../../assets/productos/cranberrybeans.png";
import mung from "../../assets/productos/mungbeans.png";
import garbanzos from "../../assets/productos/garbanzos.png";
import expeler from "../../assets/productos/expeler.jpg";
import popcorn from "../../assets/productos/popcorn.png";
import soja from "../../assets/productos/soja.jpg";
import { useTranslation } from "react-i18next";

export const Productos = () => {
    const { t, i18n } = useTranslation(["translation"]);

    const productosData = [
        {
            nombre: `${t("homePage.products.prod3")}`,
            imagen: reddark,
            texto: `${t("homePage.products.prodDesc3")}`,
        },
        {
            nombre: `${t("homePage.products.prod1")}`,
            imagen: alubia,
            texto: `${t("homePage.products.prodDesc1")}`,
        },
        {
            nombre: `${t("homePage.products.prod2")}`,
            imagen: black,
            texto: `${t("homePage.products.prodDesc2")}`,
        },
        {
            nombre: `${t("homePage.products.prod6")}`,
            imagen: cranberry,
            texto: `${t("homePage.products.prodDesc6")}`,
        },
        {
            nombre: `${t("homePage.products.prod5")}`,
            imagen: adzuki,
            texto: `${t("homePage.products.prodDesc5")}`,
        },
        {
            nombre: `${t("homePage.products.prod8")}`,
            imagen: mung,
            texto: `${t("homePage.products.prodDesc8")}`,
        },
        {
            nombre: `${t("homePage.products.prod9")}`,
            imagen: garbanzos,
            texto: `${t("homePage.products.prodDesc9")}`,
        },
        {
            nombre: `${t("homePage.products.prod4")}`,
            imagen: redlight,
            texto: `${t("homePage.products.prodDesc4")}`,
        },
        {
            nombre: `${t("homePage.products.prod11")}`,
            imagen: popcorn,
            texto: `${t("homePage.products.prodDesc11")}`,
        },
        {
            nombre: `${t("homePage.products.prod10")}`,
            imagen: expeler,
            texto: `${t("homePage.products.prodDesc10")}`,
        },
        {
            nombre: `${t("homePage.products.prod12")}`,
            imagen: soja,
            texto: `${t("homePage.products.prodDesc12")}`,
        },
    ];
    return (
        <section className="containerProductos" id="productsSection">
            <h2 className="tituloProductos">
                {" "}
                {t("homePage.products.title")}{" "}
            </h2>

            <div className="ordenCardProductos">
                {productosData.map((producto, index) => (
                    <div className="cardProductos container" key={index}>
                        <div
                            className="cardProductos inner"
                            style={{
                                backgroundImage: `url(${producto.imagen})`,
                            }}
                        >
                            <p className="textoCardProductos">
                                {producto.nombre}
                            </p>
                            <p className="nuevoTextoCardProductos">
                                {producto.texto}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
