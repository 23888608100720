import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import './config/i18next.config'
import { Suspense } from "react";
import LoadScreen from "./components/common/LoadScreen.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Suspense fallback={<LoadScreen />}>
            <App />
        </Suspense>
    </React.StrictMode>
);
