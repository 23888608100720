import "../../css/header.css";
import cas from "../../assets/cas-logo.png";
import españa from "../../assets/España.jpg";
import EEUU from "../../assets/EEUU.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

export const Header = () => {
    const { t, i18n } = useTranslation(["translation"]);

    const [hamburguer, setHamburguer] = useState("");
    function hambActive() {
        hamburguer === "" ? setHamburguer("active ") : setHamburguer("");
    }

    const [langState, setLangState] = useState(
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "es"
    );
    function swapLang() {
        langState === "es" ? setLangState("en") : setLangState("es");
        langState === "es"
            ? localStorage.setItem("lang", "en")
            : localStorage.setItem("lang", "es");
        langState === "es"
            ? i18n.changeLanguage("en")
            : i18n.changeLanguage("es");
    }

    const [headerColor, setHeaderColor] = useState("");
    window.onscroll = function () {
        window.scrollY > 0 ? setHeaderColor("white ") : setHeaderColor("");
    };

    return (
        <header className={"mainHeader " + hamburguer + headerColor}>
            <Link
                to={"/"}
                className="mainHeader__logo"
                onClick={() =>
                    hamburguer === "active " ? setHamburguer("") : ""
                }
            >
                <img
                    src={cas}
                    className="mainHeader__img"
                    alt="Cas Exportaciones Logo"
                />
            </Link>
            <nav className={"mainHeader__nav " + hamburguer}>
                <Link
                    to={"/"}
                    className="mainHeader__link"
                    onClick={hambActive}
                >
                    {t("header.home")}
                </Link>
                <HashLink
                    smooth
                    to="/#productsSection"
                    className="mainHeader__link"
                    onClick={hambActive}
                >
                    {t("header.products")}
                </HashLink>
                <Link
                    to={"/nosotros"}
                    className="mainHeader__link"
                    onClick={hambActive}
                >
                    {t("header.aboutUs")}
                </Link>
                <Link
                    to={"/politicas"}
                    className="mainHeader__link"
                    onClick={hambActive}
                >
                    {t("header.politics")}
                </Link>
                <Link
                    to={"/contacto"}
                    className="mainHeader__link"
                    onClick={hambActive}
                >
                    {t("header.contact")}
                </Link>
            </nav>
            <nav className="mainHeader__lang">
                <div>
                    <img
                        src={langState === "en" ? españa : EEUU}
                        alt="Lenguaje"
                        onClick={swapLang}
                        title={
                            langState === "es"
                                ? "Click here to change to English site"
                                : "Click para ver el sitio en Español"
                        }
                    />
                </div>
            </nav>
            <button onClick={hambActive} className="mainHeader__hamburguer">
                <span className={"bar " + hamburguer}></span>
                <span className={"bar " + hamburguer}></span>
                <span className={"bar " + hamburguer}></span>
            </button>
        </header>
    );
};
