import React from "react";
import "../../css/banner-inicio.css";
import img1 from "../../assets/inicio/banner/img1.jpeg";
import img2 from "../../assets/inicio/banner/img2.jpeg";
import img3 from "../../assets/inicio/banner/img3.jpeg";
import { useTranslation } from "react-i18next";

export const Banner = () => {
    const { t, i18n } = useTranslation(["translation"]);

    return (
        <section className="home-banner">
            <div className="banner-innerContainer">
                <img
                    className="individualPic banner-pic1"
                    src={img1}
                    alt="img 1"
                />

                <img
                    className="individualPic banner-pic2"
                    src={img2}
                    alt="img 2"
                />
                <img
                    className="individualPic banner-pic3"
                    src={img3}
                    alt="img 3"
                />

                <div className="picture-overlay">
                    <h1 className="home-bannerTitle">
                        {t("homePage.banner.title")}
                    </h1>
                    <h2 className="home-bannerSubtitle">
                        {t("homePage.banner.subtitle")}
                    </h2>
                    <h3 className="home-bannerPhrase">More Than Beans.</h3>
                </div>
            </div>
        </section>
    );
};
